import React, { ReactNode, createContext, useContext, useMemo, useState } from "react"

export const measurements: { [key: string]: Measurement } = {
	imperial: {
		id: "imperial",
	},
	metric: {
		id: "metric",
	},
}

export interface Measurement {
  id: string;

}

export interface MeasurementContextType {
  measurement?: Measurement;
  switchMeasurement: (key: Measurement) => void;
}


const MeasurementContext = createContext<MeasurementContextType>({
	measurement: measurements.metric,
	switchMeasurement: (m) => { }
})

export const useMeasurement = (): MeasurementContextType => {
	const context = useContext(MeasurementContext)
	if (context === undefined) {
		throw new Error("useMeasurement must be used within a MeasurementProvider")
	}
	return context
}

export const MeasurementProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [measurement, setMeasurement] = useState<Measurement>(measurements.metric)

	const switchMeasurement = (key: Measurement) => {
		setMeasurement(key)
	}

	const contextValue = useMemo(() => {
		return { measurement, switchMeasurement }
	}, [measurement])

	return (
		<MeasurementContext.Provider value={contextValue}>
			{children}
		</MeasurementContext.Provider>
	)
}
