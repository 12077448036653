import React, { ReactNode, createContext, useContext, useMemo, useState } from "react"

export const allAccessibilities: { [key: string]: Accessibility } = {
	ambulatory: {
		id: "ambulatory",
	},
	mobilty: {
		id: "mobilty",
	},
	hearing: {
		id: "hearing",
	},
	visual: {
		id: "visual",
	},
	cognitive: {
		id: "cognitive",
	},
	neurodiversity: {
		id: "neurodiversity",
	}
}

export interface Accessibility {
  id: string;
}

export interface AccessibilityContextType {
  accessibilities: Accessibility[];
  switchAccessibility: (a: Accessibility[]) => void;
}


const AccessibilityContext = createContext<AccessibilityContextType>({
	accessibilities: [],
	switchAccessibility: (a) => { }
})

export const useAccessibility = (): AccessibilityContextType => {
	const context = useContext(AccessibilityContext)
	return context
}

export const AccessibilityProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [accessibilities, setAccessibility] = useState<Accessibility[]>(Object.values(allAccessibilities))

	const switchAccessibility = (a: Accessibility[]) => {
		setAccessibility(a)
	}

	const contextValue = useMemo(() => {
		return { accessibilities, switchAccessibility }
	}, [accessibilities])

	return (
		<AccessibilityContext.Provider value={contextValue}>
			{children}
		</AccessibilityContext.Provider>
	)
}
